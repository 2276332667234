import { supabase } from "..";
import { toast } from "react-toastify";
import {
  makeThreeAttempts,
  sendAuditLogs,
  stripeAppearance,
  toastobj,
} from "../commonutilities";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Backdrop, CircularProgress, FormGroup } from "@mui/material";
import { Col, Input, Label, Row, Toast } from "reactstrap";
import { useSelector } from "react-redux";
import {
  getTempSubscription,
  deleteTempSubscription,
} from "../services/paymentService";
import { website_url } from "../bootapi";

export const CheckoutForm = ({ refreshtoken, customer_id, uuid }) => {
  const stripe = useStripe();
  const history = useNavigate();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [processing, setProcessing] = useState("");
  const [newloader, setnewloader] = useState(true);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setnewloader(true);
    const payload = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // return_url: `http://localhost:3000/auth/arcAuthorPayment/${refreshtoken}/${customer_id}/${uuid}`,
        return_url: `${website_url}/auth/arcAuthorPayment/${refreshtoken}/${customer_id}/${uuid}`,
      },
    });
    // const payload = await stripe.confirmCardSetup(clientsecret, {
    //   payment_method: {
    //     card: elements.getElement(CardElement),
    //   },
    // });
    if (payload.error) {
      setErrorMessage(payload.error.message);
      toast.error(`Payment failed ${payload.error.message}`, toastobj);
      setProcessing(false);
      setnewloader(false);
    } else {
      toast.info("Please wait!! We are confirming your payment", toastobj);
      setnewloader(false);
    }
  };
  useEffect(() => {
    if (elements) {
      const element = elements.getElement("payment");
      element.on("ready", () => {
        setnewloader(false);
      });
    }
  }, [elements]);

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={newloader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Row>
        <Col md={5}>
          <form onSubmit={handleSubmit}>
            {/* <CardElement id="card-element" options={cardStyle} /> */}
            <PaymentElement />
            <div className="mt-4 d-flex cg-1 justify-content-between">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  window.history.back();
                }}
              >
                Go Back
              </button>

              <button disabled={!stripe} className="btn btn-primary">
                {processing ? (
                  <div className="spinner" id="spinner"></div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
            {errorMessage && <div className="text-danger">{errorMessage}</div>}
          </form>
        </Col>
      </Row>
    </div>
  );
};

const StripeArcAudioPayment = (args) => {
  const history = useNavigate();
  const newToken = useSelector((state) => state.theme.token);
  let messagesEndRef = useRef(null);
  let [user, setuser] = useState({});
  const { refresh_session, customer_id, uuId } = useParams();
  const [loader, setloader] = useState(false);
  const [amount, setAmount] = useState();
  const [options, setoptions] = useState({});
  const [showform, setshowform] = useState(false);
  const [refreshtoken, setrefreshtoken] = useState("");
  const [stripepromise, setstripepromise] = useState();

  useEffect(() => {
    const status = new URLSearchParams(window.location.search).get(
      "redirect_status"
    );

    const getcustomerid = () => {
      setloader(true);
      supabase.auth.getSession().then((res) => {
        if (
          res.data.session &&
          res.data.session.user &&
          res.data.session.user.id
        ) {
          setuser(res.data.session.user);
          setrefreshtoken(res.data.session.refresh_token);
          getsubscription(res.data.session.user.id, newToken);
        }
      });
    };
    if (status === "succeeded") {
      sendAuditLogs(
        user?.id,
        user?.email,
        "Subscription created successfully from web_site",
        "Subscription Create"
      );
      toast.success("Payment Charged Successfully", toastobj);
      setloader(true);
      deleteTempData();
      history(`/auth/arcana_persona`);
    } else {
      getcustomerid();
    }
  }, []);
  const deleteTempData = async () => {
    deleteTempSubscription(uuId, newToken)
      .then((response) => {
        if (response.data.status) {
        }
      })
      .catch((error) => {
        setloader(false);
        // toast.error("Something went wrong");
      });
  };

  const getsubscription = async (id, newToken) => {
    if (customer_id) {
      getTempSubscription(uuId, newToken)
        .then((res) => {
          if (res.data.status) {
            let tempdata = JSON.parse(res?.data?.tem?.json);

            setAmount(tempdata?.total_amount);

            setoptions({
              clientSecret: tempdata?.clientSecret,
              appearance: stripeAppearance,
            });

            setstripepromise(loadStripe(tempdata?.publishkey));
            setTimeout(() => {
              setloader(false);
              // scrollToBottom();
              setshowform(true);
            }, 2000);
          }
        })
        .catch((error) => {
          setloader(false);
        });
    }
  };

  return (
    <div
      onClick={() => {
        args.toggleMenu();
      }}
      style={window.innerWidth < 600 ? { marginBottom: "5rem" } : {}}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {showform && options["clientSecret"] && (
        <div
          ref={messagesEndRef}
          className="container"
          style={{ marginTop: "100px" }}
        >
          <Row style={{ marginBottom: "1rem" }}>
            <Col md={5}>
              <h3 className={`mb-4`}>Payment</h3>

              <FormGroup>
                <Label>Amount</Label>
                <Input
                  // placeholder="15- March - 1985"
                  className="new-stripe-input"
                  type="text"
                  value={`$${amount ? amount : 0}`}
                  readOnly
                  disabled
                  style={{ color: "white" }}

                  // onChange={(e) => setBirthDate(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Elements stripe={stripepromise} options={options}>
            <CheckoutForm
              refreshtoken={refreshtoken}
              token={newToken}
              customer_id={customer_id}
              uuid={uuId}
            />
          </Elements>
        </div>
      )}
    </div>
  );
};

export default StripeArcAudioPayment;
