import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

const FreeTrialComp = ({ freeTrialDaysLeft }) => {
  const history = useNavigate();
  const handleClick = () => {
    history("/create_subscription");
  };
  return (
    <div className="freeTrialHomePage">
      {freeTrialDaysLeft !== null ? (
        freeTrialDaysLeft === 7 ? (
          <div
            onClick={handleClick}
            className="newFreeTrialContainer trial-container-home"
          >
            <p className="mb-0">
              You Started Your {freeTrialDaysLeft} Day Free Trial!
            </p>
          </div>
        ) : freeTrialDaysLeft > 1 && freeTrialDaysLeft < 7 ? (
          <div
            onClick={handleClick}
            className="newFreeTrialContainer trial-container-home"
          >
            <p>
              {freeTrialDaysLeft} {freeTrialDaysLeft > 1 ? "Days" : "Day"} Left
              in Your Free Trial
            </p>
            <Button type="submit" className="btn-primary" onClick={handleClick}>
              Upgrade Here
            </Button>
          </div>
        ) : freeTrialDaysLeft === 1 ? (
          <div
            onClick={handleClick}
            className="newFreeTrialContainer trial-container-home"
          >
            <p>Only 1 Day Left</p>
            <Button type="submit" className="btn-primary" onClick={handleClick}>
              Upgrade Here
            </Button>
          </div>
        ) : freeTrialDaysLeft <= 0 ? (
          <div
            onClick={handleClick}
            className="newFreeTrialContainer trial-container-home"
          >
            <p>Your FreeTrial Ended</p>
            <Button type="submit" className="btn-primary" onClick={handleClick}>
              View Plans
            </Button>
          </div>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default FreeTrialComp;
