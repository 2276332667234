import React from "react";
import "../shared/css/loader.css";
const NewLoader = () => {
  return (
    <div className="newloadercontainer">
      <span className="new_loader"></span>
    </div>
  );
};

export default NewLoader;
