import "./AppDownloadButton.css";
const AppLinks = {
  seventhsight: {
    google:
      "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.seventhsight",
    ios: "https://apps.apple.com/in/app/seventhsight-dream-analyser/id6463474694",
  },
  seventhjournal: {
    google:
      "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.seventhjournal",
    ios: "https://apps.apple.com/in/app/seventhsight-dream-analyser/id6478920389",
  },
  seventhcare: {
    google:
      "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.seventhcare",
    ios: "https://apps.apple.com/in/app/seventhsight-dream-analyser/id6502522177",
  },
  seventhguide: {
    google:
      "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.seventhguide",
    ios: "https://apps.apple.com/in/app/seventhsight-dream-analyser/id6503948482",
  },
};

const AppDownloadButtons = (props) => {
  const { type, darkMode = true } = props;
  const openLink = (url) => {
    window.open(url, "_blank");
  };

  const googleLink = AppLinks[type]?.google;
  const iosLink = AppLinks[type]?.ios;

  return (
    <div className="mt-4 flexRowStart cg-2">
      <div
        className="app-play-container pointer"
        onClick={() => openLink(iosLink)}
      >
        <image
          className={darkMode ? "apple-icon-img-dark" : "apple-icon-img"}
        />
        <div className="app-play-text-container">
          <p className="mb-0 main-text-color-new">Download on the</p>
          <p className="mb-0">App Store</p>
        </div>
      </div>
      <div
        className="app-play-container pointer"
        onClick={() => openLink(googleLink)}
      >
        <image className="googleplay-icon-img" />
        <div className="app-play-text-container">
          <p className="mb-0 main-text-color-new">Get it on</p>
          <p className="mb-0">Google Play</p>
        </div>
      </div>
    </div>
  );
};

export default AppDownloadButtons;
