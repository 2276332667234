import * as Yup from "yup";

export const EmailScheme = Yup.object({
  email: Yup.string()
    .trim()
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid email address")
    .required("Email is required"),
});
export const detailsFeedbackScheme = Yup.object({
  text: Yup.string().trim().min(5).max(30).required("Please enter reason"),
});

export const GiftCardScheme = Yup.object({
  selamount: Yup.number()
    .typeError("Amount must be a number")
    .integer("Amount cannot be in decimal")
    .min(1, "Invalid amount")
    .max(10000, "Amount must be at most 10,000")
    .test("no-decimals", "Amount cannot contain decimals", (value) =>
      Number.isInteger(value)
    )
    .test(
      "no-special-characters",
      "Amount cannot contain special characters",
      (value) => /^[0-9]+$/.test(value)
    )
    .required("Amount is required"),

  sendto: Yup.string()
    .trim()
    .min(3, "Recipent name must be atleast 3 characters")
    .matches(/[a-zA-Z]/, "Sender Name must contain only alphabets ")
    .max(15)
    .required("Recipent name is required"),
  sendemail: Yup.string()
    .trim()
    .email()
    .matches(
      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      "sendemail must be a valid email"
    )
    .required("Please enter email"),
  message: Yup.string().trim().min(4).required("Please enter message"),
  sender: Yup.string().trim().min(2).max(25).required("Please enter your name"),
  deliverdate: Yup.date()
    .required("Please choose delivery date")
    .typeError("Invalid date format"),
  isdiscountcode: Yup.boolean(),
  coupon_name: Yup.string()
    .trim()
    .when("isdiscountcode", (isdiscountcode, schema) => {
      if (isdiscountcode[0]) {
        return schema.required("Promo code is required");
      } else return schema.notRequired();
    }),
  /* discount: Yup.string().when("isdiscountcode",(isdiscountcode, schema) => {
    is: true,
    then: Yup.string()
      .required("Discount code is required")
      .matches(/^[a-zA-Z]+$/, "Invalid discount code"),
    otherwise: Yup.string().notRequired(),
  }), */
});

export const baseValidationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid email address")
    .required("Email is required"),
  /* username: Yup.string()
    .trim()
    .matches(
      /^[a-zA-Z]{2}[a-zA-Z0-9\s]*$/,
      "Username must start with exactly 2 alphabets and cannot contain special characters"
    )
    .min(2, "Username must be at least 2 characters")
    .max(15, "Username must be at most 15 characters")
    .required("Username is required"), */
  password: Yup.string()
    .trim()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmpassword: Yup.string()
    .trim()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  recaptcha: Yup.string().required("Please complete the captcha"),
});
export const signupBaseValidationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Invalid email address")
    .required("Email is required"),
  /* username: Yup.string()
    .trim()
    .matches(
      /^[a-zA-Z]{2}[a-zA-Z0-9\s]*$/,
      "Username must start with exactly 2 alphabets and cannot contain special characters"
    )
    .min(2, "Username must be at least 2 characters")
    .max(15, "Username must be at most 15 characters")
    .required("Username is required"), */
  /* password: Yup.string()
    .trim()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmpassword: Yup.string()
    .trim()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"), */
  recaptcha: Yup.string().required("Please complete the captcha"),
});
export const betaTesterValidationSchema = signupBaseValidationSchema.shape({
  coupon: Yup.string().trim().required("Coupon code is required"),
});
export const signUpValidationSchema = signupBaseValidationSchema.shape({
  newsLetter: Yup.boolean(),
});
export const addReviewValidationSchema = Yup.object({
  rating: Yup.number()
    .min(1, "Rating must be at least 1")
    .max(5, "Rating must be at most 5"),
  username: Yup.string()
    .trim()
    .min(3, "Name must be atleast 3 characters")
    .matches(/[a-zA-Z]/, "Name must contain only alphabets ")
    .max(15)
    .required("Name is required"),
  review: Yup.string()
    .trim()
    .min(4, "Review must be atleast 4 characters")
    .max(40, "Review must be at most 40 characters"),
  recaptcha: Yup.string().required("Please complete the captcha"),
});

export const deleteValidationSchema = EmailScheme.shape({
  username: Yup.string()
    .trim()
    .min(3, "Name must be atleast 3 characters")
    .matches(/[a-zA-Z]/, "Name must contain only alphabets ")
    .max(15)
    .required("Name is required"),
  recaptcha: Yup.string().required("Please complete the captcha"),
});

export const saveFremiumSchema = EmailScheme.shape({
  recaptcha: Yup.string().required("Please complete the captcha"),
});
