import React from "react";
import "./chatloader.css";

const ChatLoader = (supportChat) => {
  return (
    <div className="chat-bubble">
      <div className="loading">
        <div className="dot one"></div>
        <div className="dot two"></div>
        <div className="dot three"></div>
      </div>
      <div className={`tail ${supportChat ? "support-tail" : ""}`}></div>
    </div>
  );
};

export default ChatLoader;
